import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.$attrs['value'],"transition":"scale-transition","offset-y":"","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.$attrs, 'value', $event)},"update:return-value":function($event){return _vm.$set(_vm.$attrs, 'value', $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":_vm.label,"prepend-icon":_vm.$attrs['inner-icon'] != null ? '' : 'event',"prepend-inner-icon":_vm.$attrs['inner-icon'] != null ? 'event' : '',"readonly":"","type":"date","disabled":_vm.$attrs['disabled'] != null ? _vm.$attrs['disabled'] : false,"rules":_vm.$attrs['required'] != null
          ? _vm.$helpers.rule.notEmpty(_vm.$t('date-required'))
          : [],"solo":_vm.$attrs['solo'] != null ? _vm.$attrs['solo'] : false,"clearable":_vm.$attrs['clearable'] != null ? _vm.$attrs['clearable'] : true},on:{"click:clear":function($event){return _vm.clearClicked()}},model:{value:(_vm.$attrs['value']),callback:function ($$v) {_vm.$set(_vm.$attrs, 'value', $$v)},expression:"$attrs['value']"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VDatePicker,_vm._g(_vm._b({attrs:{"no-title":"","scrollable":"","color":"primary","first-day-of-week":1},on:{"input":function($event){return _vm.$refs.menu.save($event)}}},'v-date-picker',_vm.$attrs,false),_vm.$listeners))],1)}
var staticRenderFns = []

export { render, staticRenderFns }