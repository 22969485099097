<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="$attrs['value']"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="$attrs['value']"
        :label="label"
        :prepend-icon="$attrs['inner-icon'] != null ? '' : 'event'"
        :prepend-inner-icon="$attrs['inner-icon'] != null ? 'event' : ''"
        readonly
        v-bind="attrs"
        v-on="on"
        type="date"
        :disabled="$attrs['disabled'] != null ? $attrs['disabled'] : false"
        :rules="
          $attrs['required'] != null
            ? $helpers.rule.notEmpty($t('date-required'))
            : []
        "
        :solo="$attrs['solo'] != null ? $attrs['solo'] : false"
        :clearable="$attrs['clearable'] != null ? $attrs['clearable'] : true"
        @click:clear="clearClicked()"
      ></v-text-field>
    </template>
    <v-date-picker
      v-bind="$attrs"
      v-on="$listeners"
      no-title
      scrollable
      color="primary"
      @input="$refs.menu.save($event)"
      :first-day-of-week="1"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import { mask } from "vue-the-mask";

export default {
  name: "DatePicker",
  props: {
    appendOuterIcon: String,
    appendOuter: Function,
    label: {
      type: String,
      default: "Date",
    },
  },
  directives: {
    mask,
  },
  data() {
    return {
      menu: false,
    };
  },
  methods: {
    clearClicked() {
      this.$attrs["value"] = null;
      this.$emit("input", this.$attrs["value"]);

      this.$refs.menu.save(this.$attrs["value"]);
    },
  },
  mounted() {},
};
</script>


<style>
input[type="date"]::-ms-clear,
input[type="date"]::-webkit-clear-button,
input[type="date"]::-webkit-outer-spin-button,
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
</style>

<!--
<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="$attrs['value']"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :label="label"
        prepend-icon="event"
        v-bind="attrs"
        v-on="on"
        v-model="selected"
        v-on:input="$emit('input', $event)"
        @blur="date = parseDate($attrs['value'])"
        :rules="
          $attrs['required'] != null
            ? $helpers.rule.notEmpty($t('date-required'))
            : []
        "
        clearable
        @click:clear="clearClicked()"
      ></v-text-field>
    </template>
    <v-date-picker
      v-bind="$attrs"
      v-on="$listeners"
      v-model="selected"
      no-title
      scrollable
      color="primary"
      @input="$refs.menu.save($event)"
      :first-day-of-week="1"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import { mask } from "vue-the-mask";

export default {
  name: "DatePicker",
  props: {
    appendOuterIcon: String,
    appendOuter: Function,
    label: {
      type: String,
      default: "Date",
    },
  },
  directives: {
    mask,
  },
  data() {
    return {
      menu: false,
      date: null,
    };
  },
  computed: {
    selected: {
      get() {
        return this.$attrs["value"];
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  watch: {
    date() {
      this.$attrs["value"] = this.formatDate(this.date);
    },
  },
  methods: {
    clearClicked() {
      this.$attrs["value"] = null;
      this.$emit("input", this.$attrs["value"]);

      this.$refs.menu.save(this.$attrs["value"]);
    },
    formatDate(date) {
      if (!date) {
        return null;
      }
      return date;
    },
    parseDate(date) {
      if (!date) {
        return null;
      }

      const [year, month, day] = date.split("-");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
  mounted() {},
};
</script>

<style>
input[type="date"]::-ms-clear,
input[type="date"]::-webkit-clear-button,
input[type="date"]::-webkit-outer-spin-button,
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
</style>
-->

